{
  "page_heading": "Авангардни технологии за почти нечестно",
  "page_colored_heading": "бизнес предимство",
  "page_description": "Код. Автоматизация. Изкуствен Интелект. Плюс изпитани маркетинг и бизнес системи за разрастване на вашия бизнес или фрийланс кариера. В лабораторията на Саше не се говори тихо.",
  "get_updates": "Получи известие",
  "email_address": "Имейл Адрес",
  "official_website": "Основен сайт",
  "website_url": "https://experts.pub/bg",
  "language": "Език",
  "blog": "Блог",
  "blog_url": "https://experts.pub/bg/resources",
  "sashe_vuchkov": "Саше Вучков",
  "brought_to_you": "Изработено с кеф от",
  "something_went_wrong": "Нещо се обърка. Моля, опитайте по-късно...",
  "signing_you_up": "Обработка на заявката...",
  "go_back": "Затвори",
  "confirmed_title": "Поздравления!",
  "confirmed_body": "Вашият абонамент за бюлетина беше потвърден успешно...",
  "unsubscribe_title": "Довиждане!",
  "unsubscribe_body": "Вие се отписахте успешно от бюлетина..."
}