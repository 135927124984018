import React, {useCallback, useId, useState} from 'react'

import { Button } from './Button'
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {subscribeUrl} from '../lib/const';
import {Spinner} from './Spinner';

export function SignUpForm() {
  let id = useId()
  const { t, i18n } = useTranslation(["common"]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string|null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSignUp = useCallback(async () => {
      try {
          setIsProcessing(true);
          setMessage(null);
          const response = await axios.post(subscribeUrl, {
              email: email,
              list: ["en", "en-US"].includes(i18n.language) ? 6 : 7,
              lang: ["en", "en-US"].includes(i18n.language) ? "en" : "bg",
              variant: "lab",
              redirect_to: "https://lab.experts.pub/?confirmed=1",
          }, {
              headers: {
                  'Content-Type': 'application/json'
              }
          });
          setMessage(response.data.message);
          setIsProcessing(false);
      } catch (error: unknown) {
          let message:any = t("something_went_wrong");
          if (axios.isAxiosError(error) && error.response?.data?.message) {
              message = error.response.data.message
          }
          setMessage(message);
          setIsProcessing(false);
      }
  }, [i18n, email, t]);

  return (
      <>
          <form className="relative isolate mt-8 flex items-center pr-1">
              <label htmlFor={id} className="sr-only">
                  Email address
              </label>
              <input
                  required
                  type="email"
                  autoComplete="email"
                  name="email"
                  id={id}
                  placeholder={t("email_address")}
                  onChange={(event) => setEmail(event.target.value)}
                  className="peer w-0 flex-auto bg-transparent px-4 py-2.5 text-base text-white placeholder:text-gray-500 focus:outline-none sm:text-[0.8125rem]/6"
              />
              <Button type="button" arrow aria-disabled={!!email || isProcessing} onClick={handleSignUp}>
                  {t("get_updates")}
              </Button>
              <div className="absolute inset-0 -z-10 rounded-lg transition peer-focus:ring-4 peer-focus:ring-accent-500/15" />
              <div className="absolute inset-0 -z-10 rounded-lg bg-white/2.5 ring-1 ring-white/15 transition peer-focus:ring-accent-500" />
          </form>
          <div className="mt-5 text-sm text-white">
              {isProcessing && <Spinner variant="light">{t("signing_you_up")}</Spinner>}
              <span>{message}</span>
          </div>
      </>
  )
}
