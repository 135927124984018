import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enLang from './langs/common/en-US.json';
import bgLang from './langs/common/bg-BG.json';

const resources = {
    "en": {
        common: enLang,
    },
    "bg": {
        common: bgLang,
    },

    "en-US": {
        common: enLang
    },

    "bg-BG": {
        common: bgLang,
    },
};


i18n.use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng: "bg-BG",
    lng: "bg-BG",
    //should be disabled in production
    debug: true,
    ns: ["common"],
    interpolation: {
        escapeValue: false,
        formatSeparator: ",",
    },
    react: {
        wait: true,
    }
});

export default i18n;